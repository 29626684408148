import { lazy, Suspense } from "react";
import { IconBaseProps } from "react-icons";
import {
  MdMoveToInbox,
  MdOutlineLogout,
  MdSend,
  MdViewList,
  MdDashboard,
  MdOutlineAccountCircle,
  MdOutlineDescription,
  MdMode,
  MdAutorenew,
  MdOutlineBackup,
  MdOutlineArticle,
  MdAssignmentInd,
  MdOutlineFileCopy,
} from "react-icons/md";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";

import { KnownUsertype, KnownUsertypeName } from "api/credentialsAPI/usertype";

import { COLORS } from "assets/theme";

import LoaderPage from "components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "components/auth/AuthHOC";
import UserGate from "components/auth/UserGate";
import { SidebarItemProps } from "components/Sidebar/SidebarItem";
import { StyledToastContainerPuspenerbal } from "components/Toast/StyledToastContainerPuspenerbal";

import { ListOfRoutes, SisfoTURoutes } from "config/nav";

import Layout from "layout/Layout";

import NoMatch from "pages/NoMatch";
import Redirector from "pages/Redirector";
import DetailMailDraftPage from "pages/SisfotuPages/DetailMailDraftPage";

// import Logout from "./pages/SisfotuPages/Logout";
const LogoutPage = lazy(() => import("pages/SisfotuPages/Logout"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const InboxPage = lazy(() => import("pages/SisfotuPages/InboxPage"));
const ViewInboxDetailPage = lazy(
  () => import("pages/SisfotuPages/InboxPage/ViewInboxDetail"),
);
const OutboxPage = lazy(() => import("pages/SisfotuPages/OutboxPage"));
const ViewOutboxDetailPage = lazy(
  () => import("pages/SisfotuPages/OutboxPage/ViewOutboxDetail"),
);
const LetterTemplatePage = lazy(
  () => import("pages/SisfotuPages/LetterTemplatePage"),
);
const DraftPage = lazy(() => import("pages/SisfotuPages/DraftPage"));
// const CategoryPage = lazy(() => import("pages/SisfotuPages/CategoryPage"));
const DashboardPage = lazy(() => import("pages/SisfotuPages/DashboardPage"));
const EsignaturePage = lazy(() => import("pages/SisfotuPages/EsignaturePage"));
const ChangePwdPage = lazy(
  () => import("pages/SisfotuPages/AccountPage/ChangePwdPage"),
);
const GetOtpPage = lazy(
  () => import("pages/SisfotuPages/AccountPage/GetOtpPage"),
);
const WorkFlowPage = lazy(() => import("pages/SisfotuPages/WorkFlowPage"));

const TakahPage = lazy(() => import("pages/SisfotuPages/Takah"));
const ViewTakahPage = lazy(() => import("pages/SisfotuPages/Takah/ViewTakah"));

const NotificationPage = lazy(
  () => import("pages/SisfotuPages/NotificationPage"),
);
const ReportPage = lazy(() => import("pages/SisfotuPages/ReportPage"));

const BackupPage = lazy(
  () => import("pages/SisfotuPages/BackupRestore/BackupPage"),
);
const RestorePage = lazy(
  () => import("pages/SisfotuPages/BackupRestore/RestorePage"),
);
const ManagementAdminAccountPage = lazy(
  () => import("pages/SisfotuPages/Management/AdminAccountPage"),
);
const OperationalPage = lazy(
  () => import("pages/SisfotuPages/OperationalPage"),
);
const RolePermissionpage = lazy(
  () => import("pages/SisfotuPages/RolePermissionPage"),
);

const allowedUserAccessPage: (KnownUsertype | KnownUsertypeName)[] = [
  "kasubsiJasrek",
  "kasiWatpers",
  "kasiBintal",
  "kasiKum",
  "kasubsiEvadal",
  "kasiProfesiPenerbang",
  "kasubditWatpers",
  "personel",
  "kasiDalpersmil",
  "kasiKesud",
  "kasiMindik",
  "kasubsiDatapers",
];

/**
 * @todo each portal should have its own nav
 */
const basicNav: SidebarItemProps[] = [
  {
    Icon: () => (
      <MdDashboard size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    title: "Analisa Visual",
    parentURL: SisfoTURoutes.dashboard,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => (
      <MdMoveToInbox size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    title: "Surat Masuk",
    parentURL: SisfoTURoutes.inbox,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => <MdSend size={18} color={COLORS.white} fill={COLORS.white} />,
    title: "Surat Keluar",
    parentURL: SisfoTURoutes.outbox,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => (
      <MdAssignmentInd size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    title: "Operasional",
    parentURL: SisfoTURoutes.operational,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => (
      <MdOutlineAccountCircle
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    allowedUsers: [...allowedUserAccessPage, "superadmin"],
    parentURL: SisfoTURoutes.account,
    title: "Akun",
    kiddos: [
      {
        title: "Ubah Password",
        shrinkTitle: "UP",
        kiddoURL: SisfoTURoutes.account_changePwd,
      },
      {
        title: "GENERATE OTP",
        shrinkTitle: "GO",
        kiddoURL: SisfoTURoutes.account_getOTP,
      },
    ],
  },
  // {
  //   Icon: () => (
  //     <MdViewList
  //       size={18}
  //       color={COLORS.white}
  //       fill={COLORS.white}
  //       stroke={COLORS.white}
  //     />
  //   ),
  //   title: "Kategori",
  //   parentURL: SisfoTURoutes.category,
  //   allowedUsers: ["kasiDalpersmil"],
  // },
  {
    Icon: () => (
      <MdOutlineArticle
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
        stroke={COLORS.white}
      />
    ),
    title: "Tuldis",
    parentURL: SisfoTURoutes.template,
    allowedUsers: ["superadmin"],
  },
  {
    Icon: () => (
      <MdOutlineDescription
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
      />
    ),
    parentURL: SisfoTURoutes.report,
    title: "Laporan",
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => (
      <MdViewList size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    title: "Managemen",
    allowedUsers: ["superadmin"],
    parentURL: SisfoTURoutes.backupRestore,
    kiddos: [
      {
        title: "Akun",
        shrinkTitle: "AK",
        allowedUsers: ["superadmin"],
        kiddoURL: SisfoTURoutes.managementAdminAccount,
      },
      {
        title: "Role",
        shrinkTitle: "R",
        allowedUsers: ["superadmin"],
        kiddoURL: SisfoTURoutes.managementAdminRole,
      },
    ],
  },
  {
    Icon: () => (
      <MdOutlineBackup size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    title: "Percadangan",
    allowedUsers: ["superadmin"],
    parentURL: SisfoTURoutes.backupRestore,
    kiddos: [
      {
        title: "Backup",
        shrinkTitle: "BK",
        kiddoURL: SisfoTURoutes.backup,
      },
      {
        title: "Restore",
        shrinkTitle: "RS",
        kiddoURL: SisfoTURoutes.restore,
      },
    ],
  },

  {
    Icon: () => (
      <MdMode
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
        stroke={COLORS.white}
      />
    ),
    title: "E-Signature",
    parentURL: SisfoTURoutes.esignature,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => (
      <MdAutorenew
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
        stroke={COLORS.white}
      />
    ),
    title: "Alur Kerja",
    parentURL: SisfoTURoutes.workFlow,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: () => (
      <MdOutlineFileCopy
        size={18}
        color={COLORS.white}
        fill={COLORS.white}
        stroke={COLORS.white}
      />
    ),
    title: "Takah",
    parentURL: SisfoTURoutes.takah,
    allowedUsers: allowedUserAccessPage,
  },
  {
    Icon: (_: IconBaseProps) => (
      <MdOutlineLogout size={18} color={COLORS.white} fill={COLORS.white} />
    ),
    parentURL: SisfoTURoutes.logout,
    title: "Keluar",
  },
];

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              index
              element={<Navigate to={`${ListOfRoutes.loginpage}`} />}
            />

            <Route
              path={SisfoTURoutes.base}
              element={
                <Layout sidebarItems={basicNav}>
                  <AuthHOC>
                    <Outlet />
                  </AuthHOC>
                </Layout>
              }
            >
              <Route index element={<Redirector />} />

              <Route
                path={`${SisfoTURoutes.dashboard}`}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <DashboardPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.inbox}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <InboxPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.operational}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <OperationalPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.template}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <LetterTemplatePage />
                  </UserGate>
                }
              />
              <Route
                path={SisfoTURoutes.report}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <ReportPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoTURoutes.notification}`}
                element={<NotificationPage />}
              />

              <Route
                path={SisfoTURoutes.account_changePwd}
                element={
                  <UserGate
                    allowedUsers={[...allowedUserAccessPage, "superadmin"]}
                  >
                    <ChangePwdPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.account_getOTP}
                element={
                  <UserGate
                    allowedUsers={[...allowedUserAccessPage, "superadmin"]}
                  >
                    <GetOtpPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.esignature}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <EsignaturePage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.workFlow}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <WorkFlowPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.takah}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <TakahPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoTURoutes.getTakahDetail(":id")}`}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <ViewTakahPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoTURoutes.getInboxDetail(":id")}`}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <ViewInboxDetailPage />
                  </UserGate>
                }
              />
              <Route
                path={SisfoTURoutes.outbox}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <OutboxPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.backup}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <BackupPage />
                  </UserGate>
                }
              />

              <Route
                path={SisfoTURoutes.restore}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <RestorePage />
                  </UserGate>
                }
              />
              <Route
                path={`${SisfoTURoutes.getOutboxDetail(":id")}`}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <ViewOutboxDetailPage />
                  </UserGate>
                }
              />
              <Route
                path={SisfoTURoutes.draft}
                element={
                  <UserGate allowedUsers={["kasiDalpersmil"]}>
                    <DraftPage />
                  </UserGate>
                }
              />
              {/* <Route
                path={SisfoTURoutes.category}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <CategoryPage />
                  </UserGate>
                }
              /> */}
              <Route
                path={`${SisfoTURoutes.getDraftDetail(":id")}`}
                element={
                  <UserGate allowedUsers={allowedUserAccessPage}>
                    <DetailMailDraftPage />
                  </UserGate>
                }
              />

              <Route
                path={`${SisfoTURoutes.managementAdminAccount}`}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <ManagementAdminAccountPage />
                  </UserGate>
                }
              />
              <Route
                path={`${SisfoTURoutes.managementAdminRole}`}
                element={
                  <UserGate allowedUsers={["superadmin"]}>
                    <RolePermissionpage />
                  </UserGate>
                }
              />

              <Route path={SisfoTURoutes.logout} element={<LogoutPage />} />
            </Route>
          </Route>

          <Route
            path={SisfoTURoutes.login}
            element={
              <Suspense fallback={<LoaderPage />}>
                <Outlet />
              </Suspense>
            }
          >
            <Route index element={<LoginPage />} />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
      <StyledToastContainerPuspenerbal />
    </>
  );
}

export default App;
